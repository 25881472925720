.clipBoard {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}

.clipScroller {
    width: 100%;
    padding: 5rem 0;
    overflow-y: auto;
    margin: 0 auto;

}

.clipClop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 1.5rem;
}

.clipWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.clip {
    padding: 1rem;
    border-radius: 1rem;
    word-break: break-all;
}

.clipWrapper .clip {
    max-width: calc(100vw - (10rem - 48px));
}

.clipWrapper.link .clip {
    max-width: calc(100vw - 10rem);
}

.clipWrapper:not(.copied) .clip {
    transition: background-color 500ms linear, color 500ms linear;
}

.clipWrapper.copied .clip {

    background-color: #c0ffee;
    color: black

    /*animation: background-fade 1s forwards;*/
}

@keyframes background-fade {
    0% {
        background-color: #c0ffee;
        color: black
    }
    100% {
        background-color: var(--primary);
        color: var(--contrast)
    }
}

.clipWrapper a svg {
    width: 3rem;
    height: 3rem;
}

.clipWrapper > svg {
    width: 2rem;
    height: 2rem;
}

.addClip {
    display: flex;
    align-items: center;
    justify-content: center;
}

.addClip button {
    margin-left: 1rem;
}

.danger {
    fill: red;
}

.clipInput {
    display: block;
    min-height: 2rem;
    width: calc(100vw - 11rem);
    border-radius: .5rem;
    margin-left: 2rem;
}