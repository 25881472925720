
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.loader svg {
    --size: min(80vh, 80vw);
    height: var(--size);
    width: var(--size);
}

.loader .bg {
    fill: none;
}

.loader .bar {
    fill: var(--primary);
    transition:
    width 1s linear,
    height 1s linear,
    x 1s linear,
    y 1s linear;
}

.loader .frame {
    --time: 400ms;
    transition:
    width var(--time) linear,
    height var(--time) linear,
    x var(--time) linear,
    y var(--time) linear;
}

.loader .frame {

}

/*STAGE 1*/
.stage_1 .bar {
    width: 40px
}

/*STAGE 2*/
.stage_2 .bar {
    width: 40px;
}

.stage_2 .frame {
    width: 60px;
}

/*STAGE 3*/

.stage_3 .frame,
.stage_3 .bar {
    width: 60px;
}

/*STAGE 4*/
.stage_4 .bar {
    width: 60px;
}

.stage_4 .frame {
    width: 80px;
    x: 10px;
}

/*STAGE 5*/
.stage_5 .bar,
.stage_5 .frame {
    width: 80px;
    x: 10px;
}

/*STAGE 6*/
.stage_6 .bar {
    width: 80px;
    x: 10px;
}

.stage_6 .frame {
    width: 80px;
    x: 10px;
    height: 40px;
}

/*STAGE 7*/
.stage_7 .bar,
.stage_7 .frame {
    width: 80px;
    x: 10px;
    height: 40px;
}

/*STAGE 8*/
.stage_8 .bar{
    width: 80px;
    x: 10px;
    height: 40px;
}
.stage_8 .frame {
    width: 80px;
    x: 10px;
    y: 10px;
    height: 80px;
}

/*STAGE 9*/
.stage_9 .bar,
.stage_9 .frame {
    width: 80px;
    x: 10px;
    y: 10px;
    height: 80px;
}

/*STAGE 10*/
.stage_10 .bar {
    width: 80px;
    x: 10px;
    y: 10px;
    height: 80px;
}
