.App {
  text-align: center;
  --primary: midnightblue;
  --contrast: white;
  --secondary: mediumpurple;
  /*--secondary: dodgerblue;*/
  --secondaryContrast: black;
  position: fixed;
  background-color: var(--secondary);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

input {
  /*background-color: rgba(0,0,0,0);*/
  /*color: inherit;*/
  padding: 3px !important;
  margin: 0;
  font-size: inherit;
  border: none !important;
}

input,
button {
  outline: none;
}

button {
  border: none;
}

.primary {
  background-color: var(--primary);
  color: var(--contrast);
}

.primary > svg path {
  fill: var(--contrast);
}

.secondary {
  background-color: var(--contrast);
  color: var(--primary);
}

.secondary > svg path {
  fill: var(--secondaryContrast);
}

.basic.button {
  font-weight: bold;
  font-size: 1rem;
  padding: 1rem 2rem;
  border-radius: 1.5rem;
}

.basic.button.slim {
  font-weight: bold;
  font-size: 1rem;
  padding: .5rem 1rem;
  border-radius: 1rem;
}

.flexCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}