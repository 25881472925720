.menuButton {
    z-index: 99913;
    font-size: 1.3em;
    padding: 0;
    cursor: pointer;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

    position: fixed;
    top: 10px;
    right: 10px;
    border-radius: 5px;
}

.menu {
    padding-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 999;

    position: fixed;
    top: 0;
    left: calc(100vw + 20px);
    right: calc(-80vw - 20px);
    bottom: 0;
    transition: left 400ms ease-out, right 400ms ease-out;
}

.menuTouchOutsideSensor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*z-index: 1;*/
    background-color: #e3e6e850;
    z-index: 998;
    opacity: 0;
    pointer-events: none;
    transition: opacity 400ms ease-out;
}

.menuTouchOutsideSensor.show {
    opacity: 1;
    pointer-events: unset;
}

.menu.show {
    left: 20vw;
    right: 0;
}

.menu a {
    margin-bottom: 10px;
}

.menu a * {
    pointer-events: none; /* allow menu item click detection to close menu*/
}
